import React from 'react'
import ReactDOM from 'react-dom'
import Home from './Home'
import Demo from './Demo'
import Utils from './Utils'

import './index.css'
import './tailwind.output.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

ReactDOM.render(
  <Router>
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/demo">
        <Demo />
      </Route>
      <Route exact path="/tailwind-utils">
        <Utils />
      </Route>
    </Switch>
  </Router>,
  document.getElementById('root')
)
