import React from 'react'
import Utils from '@ui-devtools/tailwind-utils'
import { properties } from '@ui-devtools/tailwind-utils/dist/properties.js'
import flattenColorPalette from 'tailwindcss/lib/util/flattenColorPalette'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

import { Combobox, Listbox } from '@headlessui/react'

const { parse, classname, meta } = Utils()
meta.pseudoModifiers.reverse()

const getScale = propertyName => {
  const scaleName = properties[propertyName].scale
  let scale = meta.resolvedConfig.theme[scaleName]

  scale =
    scaleName.includes('color') || scaleName.includes('Color')
      ? flattenColorPalette(scale)
      : scale

  delete scale.negativeValuesAdded

  return scale
}

const App = () => {
  // classname query
  const defaultQueryString = 'md:focus:bg-red-500'

  const [queryString, setQueryString] = React.useState(defaultQueryString)
  const [parsedDefinition, setParsedDefinition] = React.useState(
    parse(queryString)
  )

  React.useEffect(() => {
    const definition = parse(queryString)

    if (definition.property === 'ERROR') definition.property = 'Unidentified'
    if (definition.value === 'ERROR') definition.value = 'Unidentified'

    setParsedDefinition(definition)

    if (
      definition.property !== 'Unidentified' &&
      definition.value !== 'Unidentified'
    ) {
      setUserDefinition(definition)

      let scale = getScale(definition.property)
      if (scale) {
        setPossibleValues(
          Object.values(scale).filter(
            value => !['inherit', 'currentColor', 'transparent'].includes(value)
          )
        )
      }
    }
  }, [queryString])

  // classname generation
  const [userDefinition, setUserDefinition] = React.useState(
    parse(defaultQueryString)
  )

  const [possibleValues, setPossibleValues] = React.useState(
    Object.values(getScale('backgroundColor'))
  )
  const [error, setError] = React.useState({})

  const updateUserDefinition = (key, value) => {
    let newUserDefition = {
      ...userDefinition,
      [key]: value === '-' ? null : value
    }

    // if propery was changed, update possibleValues and userDefinition
    if (key === 'property') {
      if (
        properties[value] &&
        properties[value].scale &&
        meta.resolvedConfig.theme[properties[value].scale]
      ) {
        let scale = getScale(value)
        const newPossibleValues = Object.values(scale).filter(
          value => !['inherit', 'currentColor', 'transparent'].includes(value)
        )
        setPossibleValues(newPossibleValues)
        if (value.includes('Color')) newUserDefition.value = '#ef4444'
        else newUserDefition.value = newPossibleValues[0]
      } else {
        // idk what to do
        setPossibleValues([])
      }
    }

    setUserDefinition(newUserDefition)

    const {
      className: userGeneratedClassName,
      error: userGeneratedError
    } = classname(newUserDefition)

    if (!userGeneratedError) {
      setQueryString(userGeneratedClassName)
      setError({})
    } else {
      if (userGeneratedError.property === 'UNIDENTIFIED_PROPERTY') {
        // todo: absorb in the library
        userGeneratedError.property = 'Property not supported yet'
        // this is a bit silly, but we need to explicitly set property
        // because it's not actually part of parsed
        if (key === 'property') {
          setParsedDefinition({ ...parsedDefinition, property: value })
        }
      }
      setError(userGeneratedError)
    }
  }

  return (
    <div
      id="utils"
      className="flex justify-center min-h-screen w-full pt-20 sm:pt-48  pb-64 bg-gray-100"
    >
      <div className="w-full max-w-2xl">
        <div className="sticky top-0 w-full bg-gray-100 z-10 px-8 py-2 mb-8 sm:mb-20">
          <input
            type="text"
            autoFocus
            spellCheck="false"
            className="w-full rounded-md p-4 pl-2 px-2 py-4 text-md sm:text-lg text-center bg-white border-2 border-blue-400 font-mono"
            value={queryString}
            onChange={event => setQueryString(event.target.value)}
          />
        </div>

        <div className="flex flex-col gap-4 px-8">
          <Select
            label="responsive modifier"
            options={['-', ...meta.responsiveModifiers]}
            value={parsedDefinition.responsiveModifier}
            onChange={value =>
              updateUserDefinition('responsiveModifier', value)
            }
          />
          <Select
            label="pseudo modifier"
            options={['-', ...meta.pseudoModifiers]}
            value={parsedDefinition.pseudoModifier}
            onChange={value => updateUserDefinition('pseudoModifier', value)}
          />

          <AutoComplete
            label="property"
            options={Object.keys(properties)}
            value={parsedDefinition.property}
            error={error.property}
            onChange={value => {
              updateUserDefinition('property', value)
            }}
          />

          <AutoComplete
            label="value"
            options={possibleValues}
            value={parsedDefinition.value}
            error={error.value}
            showSwatch={parsedDefinition.property
              .toLowerCase()
              .includes('color')}
            onChange={value => {
              updateUserDefinition('value', value)
            }}
          />

          <span className="block mt-10 font-mono text-sm text-center break-keep">
            extracted from{' '}
            <a
              href="/"
              className="text-blue-600 hover:underline"
              style={{ textUnderlineOffset: '4px' }}
            >
              <svg
                aria-hidden="true"
                className="inline ml-1 mr-2"
                width="18"
                height="18"
                viewBox="0 0 27 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.8824 15V5C22.8824 2.79086 21.0915 1 18.8823 1H5C2.79086 1 1 2.79087 1 5.00001V18.8824C1 21.0915 2.79086 22.8824 5 22.8824H14.5"
                  stroke="#3793E0"
                  strokeWidth="2"
                />
                <path
                  d="M16.5294 16.5293L20.0584 24.9999L21.3113 21.3112L25 20.0583L16.5294 16.5293Z"
                  fill="black"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.5209 21.521L25.5209 25.521"
                  stroke="black"
                  fill="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              UI Devtools
            </a>
            ,
            <br />
            for open source
            <br className="block sm:hidden" />
            <a
              className="text-blue-600 hover:underline"
              style={{ textUnderlineOffset: '4px' }}
              href="https://github.com/ui-devtools/tailwind-utils"
            >
              <svg
                aria-hidden="true"
                className="inline ml-2 mr-1"
                width="24"
                height="24"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.49936 0.850006C3.82767 0.850006 0.849976 3.8273 0.849976 7.50023C0.849976 10.4379 2.75523 12.9306 5.39775 13.8104C5.73047 13.8712 5.85171 13.6658 5.85171 13.4895C5.85171 13.3315 5.846 12.9135 5.84273 12.3587C3.99301 12.7604 3.60273 11.4671 3.60273 11.4671C3.30022 10.6988 2.86423 10.4942 2.86423 10.4942C2.26044 10.0819 2.90995 10.0901 2.90995 10.0901C3.57742 10.137 3.9285 10.7755 3.9285 10.7755C4.52167 11.7916 5.48512 11.4981 5.86396 11.3279C5.92438 10.8984 6.09625 10.6053 6.28608 10.4391C4.80948 10.2709 3.25695 9.70063 3.25695 7.15241C3.25695 6.42615 3.51618 5.83298 3.94157 5.368C3.87299 5.1998 3.64478 4.52375 4.00689 3.60807C4.00689 3.60807 4.56494 3.42926 5.83538 4.28941C6.36568 4.14204 6.93477 4.06856 7.50018 4.0657C8.06518 4.06856 8.63386 4.14204 9.16498 4.28941C10.4346 3.42926 10.9918 3.60807 10.9918 3.60807C11.3548 4.52375 11.1266 5.1998 11.0584 5.368C11.4846 5.83298 11.7418 6.42615 11.7418 7.15241C11.7418 9.70716 10.1868 10.2693 8.70571 10.4338C8.94412 10.6392 9.15681 11.045 9.15681 11.6655C9.15681 12.5542 9.14865 13.2715 9.14865 13.4895C9.14865 13.6675 9.26867 13.8745 9.60588 13.8095C12.2464 12.9282 14.15 10.4375 14.15 7.50023C14.15 3.8273 11.1723 0.850006 7.49936 0.850006Z"
                  fill="currentColor"
                />
              </svg>
              @ui-devtools/tailwind-utils
            </a>
          </span>
        </div>
      </div>
    </div>
  )
}

export default App

const Select = ({ label, options, value, onChange }) => {
  return (
    <div className="font-mono w-full flex flex-col gap-1 sm:flex-row sm:gap-0 sm:justify-between sm:items-center">
      <label className="uppercase text-sm">{label}</label>
      <Listbox value={value} onChange={onChange}>
        <div className="relative">
          <Listbox.Button className="bg-white w-full sm:w-64 rounded-md pl-2 py-2 pr-8 text-left sm:text-right border-2 border-gray-300">
            <span className="block h-6">{value || '-'}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>

          <Listbox.Options className="absolute mt-1 w-full sm:w-64 z-10 max-h-60 overflow-auto rounded-md bg-white py-1 text-hoverse shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm text-left sm:text-right">
            {options.map(option => (
              <Listbox.Option
                key={option}
                value={option}
                className={({ active, selected }) =>
                  `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                    selected && !active ? 'bg-blue-100' : ''
                  } ${active ? 'bg-blue-500 text-white' : 'text-gray-900'}`
                }
                selected={value === option}
              >
                <span>{option}</span>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </Listbox>
    </div>
  )
}

const AutoComplete = ({
  label,
  options,
  value,
  error,
  showSwatch,
  onChange
}) => {
  const [query, setQuery] = React.useState('')

  const filteredOptions =
    query === ''
      ? options
      : options.filter(option => {
          return option.toLowerCase().includes(query.toLowerCase())
        })

  React.useEffect(() => {
    setQuery('')
  }, [value])

  return (
    <>
      <div className="font-mono w-full flex flex-col sm:flex-row sm:justify-between sm:items-center">
        <label
          className={`uppercase text-sm font-mono ${
            error ? 'text-red-500' : ''
          }`}
        >
          {label}
        </label>
        <Combobox value={value} onChange={onChange}>
          <div className="relative mt-1">
            <Combobox.Input
              spellCheck="false"
              className={`bg-white w-full sm:w-64 rounded-md pl-2 py-2 pr-8 text-left sm:text-right border-2 ${
                error ? 'border-red-500 text-red-500' : 'border-gray-300'
              }`}
              onChange={event => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className={`h-5 w-5  ${
                  error ? 'text-red-500' : 'text-gray-400'
                }`}
                aria-hidden="true"
              />
            </Combobox.Button>

            <Combobox.Options className="absolute z-10 mt-1 w-full sm:w-64 max-h-60 overflow-auto rounded-md bg-white py-1 text-hoverse shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm text-left sm:text-right">
              {filteredOptions.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  No properties found with {query}
                </div>
              ) : (
                filteredOptions.map(option => (
                  <Combobox.Option
                    key={option}
                    className={({ active, selected }) =>
                      `relative cursor-pointer select-none py-2 px-6 ${
                        selected && !active ? 'bg-blue-100' : ''
                      } ${active ? 'bg-blue-500 text-white' : 'text-gray-900'}`
                    }
                    value={option}
                  >
                    <span className="flex justify-start sm:justify-end sm:order:reverse items-center gap-2 truncate">
                      {showSwatch && (
                        <span
                          className="inline-block w-4 h-4 rounded shadow-inner"
                          style={{ backgroundColor: option }}
                        />
                      )}
                      {option}
                    </span>
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </div>
        </Combobox>
      </div>
      {error && (
        <span className="font-mono text-red-600 text-right text-sm">
          {error}
        </span>
      )}
    </>
  )
}
