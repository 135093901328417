import React from 'react'
import { Devtools } from '@ui-devtools/tailwind/dist/main'

window.SKIP_CONFIG = true
window.DEMO_WEBSITE = true

const App = () => {
  return (
    <Devtools>
      <div className="fixed sm:hidden w-full bottom-0 bg-red-100 text-red-800 p-2 text-sm border-t border-red-300">
        The devtool isn't optimised for mobile, open this page on desktop.
      </div>
      <div
        className="font-mono text-xs absolute text-gray-500"
        style={{ top: 16, left: 16 }}
      >
        Start by pressing{' '}
        <span
          className="bg-gray-100 border border-gray-300 rounded w-6 inline-flex justify-center text-sm"
          style={{
            boxShadow: '0 1px 0px rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset'
          }}
        >
          `
        </span>{' '}
        or clicking the inspect button →{' '}
      </div>
      <div className="bg-gray-100 flex justify-center items-center w-full h-screen">
        <div className="shadow w-64 rounded bg-white">
          <img src="erin-lindford.jpg" alt="" className="w-16 h-16" />
          <div>
            <div>Erin Lindford</div>
            <div>Product Engineer</div>
            <div>erin.landford@acme.com</div>
            <div>(555) 765-4321</div>
          </div>
        </div>
      </div>
    </Devtools>
  )
}

export default App
